const menuButton = document.querySelector('.menu__button');
const hamburger = document.querySelector('.hamburger');
const menuContainer = document.querySelector('.menu__container');

menuButton.addEventListener('click', () => {
  menuContainer.classList.toggle('menu__container--open');
  hamburger.classList.toggle('hamburger--close');
  const menuClasses = [...menuContainer.classList];
  if (menuClasses.indexOf('menu__container--open') !== -1) {
    window.addEventListener('scroll', (event) => event.preventDefault());
  }
});
